@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=ZCOOL+KuaiLe&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Cool';
  src: url('./prison_walls.ttf') format('truetype');
}

:root {
  --primary-font: "Inter", sans-serif;
  --secondary-font: 'Cool', cursive;
}

* {
  font-family: var(--primary-font);
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  outline: none;
  border: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

h1,h2,h3,h4,h5,h6,p,span,a,small {
  color: #ffffff;
}

a, button, .icon, img {
  user-select: none;
  background: none;
  cursor: pointer;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

.lorem {
  color: #b27400;
}

.ease-250 {
  @apply transition-all duration-[250ms] ease-in-out
}
.ease-300 {
  @apply transition-all duration-[300ms] ease-in-out
}
.ease-350 {
  @apply transition-all duration-[350ms] ease-in-out
}
.ease-400 {
  @apply transition-all duration-[400ms] ease-in-out
}

/* Animation Up */
.scroll_up_animation {
  animation: slideUp 20s linear infinite;
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}

/* Animation Left */
.slide_left_animation {
  animation: slideLeft 6s linear infinite;
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-50%));
  }
}

/* Animation Right */
.slide_right_animation {
  animation: slideRight .5s linear forwards;
}

@keyframes slideRight {
  0% {
    transform: translateX(calc(-50%));
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Animation Up and Down */
.jump_up_down {
  animation: jumpUpDown 2.5s linear infinite;
}

.jump_up_down.first {
  animation-delay: 1.25s;
}

@keyframes jumpUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5rem);
  }
  100% {
    transform: translateY(0);
  }
}

.sticky_bg {
  transform: translateY(0) !important;
}


/* ::::::: shake animation */
.shake {
  animation: shakeAnimation 0.5s linear infinite;
  animation-delay: 2s;
}

@keyframes shakeAnimation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(1deg);
  }
  50% {
    transform: rotate(-1deg);
  }
  75% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}